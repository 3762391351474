import { createContext, useState,useEffect } from "react";
import useQueryParams from "../Hooks/useQueryParams";

export const AppContext = createContext();

export const AppProvider = ({children}) =>{
    const { queryPlatform, queryAppType } = useQueryParams();
    const [platform,setPlatform] = useState(null);
    const [appType,setAppType] = useState(null);
    useEffect(() => {
        if (queryPlatform) {
          setPlatform(queryPlatform);
        }
        if (queryAppType) {
          setAppType(queryAppType);
        }
      }, [queryPlatform, queryAppType]);
    return (
        <AppContext.Provider value={{platform,appType}}>
            {children}
        </AppContext.Provider>
    );
  
};