import { Fragment } from 'react';
import { Link } from "react-router-dom";
import classes from './ClaimForm.module.css';
import mainclasses from './../../style.module.css';
import { format } from "date-fns";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const MizoramFormSubmit = (props) => {
    console.log(props)
    let drawdate=format(new Date(props.mizoFormData.drawdate),'dd/MM/yyyy');
    const printdata=() => {
        window.print()
    }
    const pdfGenerate = () => {
        html2canvas(document.getElementById("printTable"),{
            scrollX: 0,
            scrollY: -window.scrollY
        }).then(function(canvas) {
            var imgData = canvas.toDataURL("image/jpeg", 1.0);
            var pdf = jsPDF("p", "mm", "a4");
            var width = pdf.internal.pageSize.getWidth()-5;
            var height = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
            pdf.save("MizoramForm.pdf");
        });
    };
    return (
        <Fragment>
            <div id="printTable" className="container">
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <Link to="/"><img src={process.env.PUBLIC_URL + "/images/Govt_of_Mizoram.png"} className={classes.mizo_logo} alt="Mizoram Govt Logo"/></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <h3 className="form_head">Application for Lottery prize claim</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 mt-5">
                        <p className={mainclasses.para1}>To,</p>
                        <p className={mainclasses.para1}>The Director</p>
                        <p className={mainclasses.para1}>Institutional Finance & State Lottery</p>
                        <p className={mainclasses.para1}>Government Of Mizoram, Tuikhuahtlang,</p>
                        <p className={mainclasses.para1}>Aizawl - 796001, Mizoram.</p>
                    </div>
                    <div className={`col-2 ${classes.uplimg_div}`}>
                        <img id="output" src={props.mizoFormData.photo} className={classes.uploadImgPreview} alt=""/>
                        <label className={classes.uplimg_text}>Winner Photo  </label>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <p className={mainclasses.para1}>I Herewith submit my prize winning ticket for your kind consideration</p>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Prize Winning Ticket No </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" className={`${classes.form_submit_inp}`}
                            placeholder="Prize Winning Ticket No *" value={props.mizoFormData.ticketno} name="win_ticketno" readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Name of Lottery </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" className={`${classes.form_submit_inp}`} placeholder="Lottery Name *"
                            name="lott_name" value={props.mizoFormData.lotteryname} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Draw No </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-5 mt-4 pl-0">
                                <input type="text" className={`${classes.form_submit_inp}`}
                                    placeholder="Draw No *" name="draw_no" value={props.mizoFormData.drawno} readOnly />
                            </div>
                            <div className="col-2 text-lg-center mt-4">
                                <label className="font-weight-bold mt-2">Drawdate </label>
                            </div>
                            <div className="col-5 mt-4 pl-0">
                                <input placeholder="Held On *" className={`${classes.form_submit_inp}`} type="text"
                                    id="" name="draw_date"
                                    value={drawdate} readOnly />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Full Name Of The prize Winner </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Full Name Of The prize Winner (Beneficiary) *"
                            className={`${classes.form_submit_inp}`} name="winner_name" value={props.mizoFormData.name} readOnly />
                    </div>
                </div>


                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Address </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Address 1 *" className={`${classes.form_submit_inp}`}
                            name="address1" value={props.mizoFormData.address1} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Address 2 *" className={`${classes.form_submit_inp}`}
                            name="address2" value={props.mizoFormData.address2} readOnly />
                    </div>
                </div>


                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Father's/Husband's Name </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Father's/Husband's Name *"
                            className={`${classes.form_submit_inp}`} name="fath_husb_name" value={props.mizoFormData.fname} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Profession </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-2 pl-lg-3 mt-4">
                                <label className="font-weight-bold mt-2"> Business </label>
                            </div>
                            <div className="col-4 pl-0 mt-4">
                                <input type="text" placeholder="Business *"
                                    className={`${classes.form_submit_inp}`} name="bussiness"
                                    value={props.mizoFormData.profession_bussiness} readOnly />
                            </div>
                            <div className="col-3 text-lg-center mt-4">
                                <label className="font-weight-bold mt-2"> Or Service</label>
                            </div>
                            <div className="col-3 pl-0 mt-4">
                                <input type="text" placeholder="Service *" className={`${classes.form_submit_inp}`}
                                    name="service" value={props.mizoFormData.profession_service} readOnly />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Name Of The Organization </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Name Of The Organization *"
                            className={`${classes.form_submit_inp}`} name="organ_name" value={props.mizoFormData.organisation_name} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Nature Of Bussiness </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Nature Of Business *"
                            className={`${classes.form_submit_inp}`} name="nature_bussiness" value={props.mizoFormData.natureof_business} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Rank Of Prize </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Rank Of Prize *" className={`${classes.form_submit_inp}`}
                            name="rank_prize" value={props.mizoFormData.prizerank} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Prize Amount </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Prize Amount *" className={`${classes.form_submit_inp}`}
                            name="prize_amt" value={props.mizoFormData.prizeamt} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">My PAN No is </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="My PAN No is *" className={`${classes.form_submit_inp}`}
                            name="pan_no" value={props.mizoFormData.panno} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">My Contact No is </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="My Contact No is *"
                            className={`${classes.form_submit_inp}`} name="cont_no" value={props.mizoFormData.mobile} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">My Bank Account No. is </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Bank A/c No. of Prize Winner (Beneficiary) *"
                            className={`${classes.form_submit_inp}`} name="bank_acno" value={props.mizoFormData.bank_account_no} readOnly />
                    </div>
                </div>
                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2"> IFSC </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 px-lg-3 pl-0 mt-4">
                        <input type="text" placeholder="IFSC Code *"
                            className={`${classes.form_submit_inp}`} name="ifsc_code" value={props.mizoFormData.ifsc_code} readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className={`col-4 mt-4 ${classes.label_div}`}>
                        <label className="font-weight-bold mt-2">Bank Name & Branch </label>
                        <label className="font-weight-bold mt-2">:</label>
                    </div>
                    <div className="col-8 mt-4">
                        <input type="text" placeholder="Bank Name & Branch *"
                            className={`${classes.form_submit_inp}`} name="bank_name" value={props.mizoFormData.bank_name + " - " + props.mizoFormData.bank_branch} readOnly />
                    </div>
                </div>

                <div className=" mt-2">
                    <div className=" px-3">
                        <div className="">
                            <div className="mt-4 pl-lg-3 pl-md-3">
                                <p className={mainclasses.para1}>Enclosed : </p>
                            </div>
                            <div className="ml-lg-4 ml-md-4 mt-4">
                                <p className={mainclasses.para1}>(1) Original Prize Winning Lottery Ticket</p>
                                <p className={mainclasses.para1}>(2) Affidavit duly attested by 1</p>
                                <p className={mainclasses.para1}>(3) Photo Copy of PAN</p>
                                <p className={mainclasses.para1}>(4) Photo Copy of Bank Pass Book/ Crossed Cheque</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-9 mt-4 ${classes.label_div}`}>
                    </div>
                    <div className="col-3 ml-auto text-center mt-5">
                        <input type="text" className={`${classes.form_submit_inp}  text-center`} name="branch"
                            readOnly />
                        <p className={classes.sign_text}>Signature</p>
                    </div>
                </div>


            </div>
            <div className={mainclasses.noprint}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mt-5">
                            <button className={`btn ${classes.form_btn} mr-2`} onClick={printdata} >print</button>
                            <button className={`btn ${classes.form_btn}`} onClick={pdfGenerate}>save as pdf</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MizoramFormSubmit;