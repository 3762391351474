import { Fragment, useState, useEffect, useCallback } from "react";
import ResultCard from "./ResultCard";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import API from "../../Api";
import Loader from "../UI/Loader/Loader";
import classes from "./TodayResult.module.css";

const TodayResult = () => {
  const todayDate = new Date();
  const [lotteryData, setLotteryData] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);

  const fetchLotteryList = useCallback(async () => {
    setIsLoading(true);
    try {
      let selectedDate = format(todayDate, "yyyy-MM-dd");
      API.post(`get-Lottery-on-drawdate`, { drawdate: selectedDate })
        .then((res) => {
          setLotteryData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchLotteryList();
  }, [fetchLotteryList]);

  let content = "";
  if (lotteryData.data.length > 0) {
    content = <ResultCard LotteryDetails={lotteryData} callFrom="TODAY" />;
  }
  if (isLoading) {
    content = <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>
          Rajshree Lottery Results - Todays Results | Rajshree Lottery
        </title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Here you can checkout todays rajshree lottery results and download it easily. Fastest way to get todays rajshree lottery results."
        />
      </Helmet>
      <div className="container">
        <div className="mt-5">
          <div className="row">
            <div className="col-md-12 text-center p-0">
              <h2 className={classes.result_heading}>Today's Results</h2>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className={classes.result_heading1}>
                {format(todayDate, "dd/MM/yyyy")}
              </h2>
            </div>
          </div>
        </div>
        {content}
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-5 mx-auto text-center">
            <h3 className={classes.result_heading1}>
              Rajshree Lottery Result Today | Check Rajshree Lottery Result
            </h3>
            <p className={classes.para}>
              Check rajshree lottery result today. Fastest way to get todays
              rajshree lottery result.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodayResult;
