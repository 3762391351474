import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../Context/AppContext';

const useRetainQueryParams = () => {
  const { platform, appType } = useContext(AppContext);

  const appendQueryParams = (path) => {
    const queryParams = new URLSearchParams({
      platform: platform || '',
      app_type: appType || ''
    }).toString();
    if(platform && appType)
        return `${path}?${queryParams}`;
    else
        return `${path}`;
  };

  return { appendQueryParams };
};

export default useRetainQueryParams;
