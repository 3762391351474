import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getToken, getMessaging, onMessage, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

console.log('*** Environment ***', process.env.REACT_APP_ENV)
//console.log('*** Firebase Config ***', firebaseConfig)

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// Initialize messaging asynchronously
const messagingPromise = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(firebaseApp);
        }
        console.log('Firebase not supported this browser');
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
})();

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        console.log("serveice worker");
        //if (serviceWorker) return serviceWorker;
        console.log("serveice worker1");
        return window.navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = async () => {
    try {
        // Wait for messaging to be initialized
        const messagingResolve = await messagingPromise;
        if (!messagingResolve) {
            console.log('Messaging is not initialized');
            return null;
        }
        
        // Get service worker registration
        const serviceWorkerRegistration = await getOrRegisterServiceWorker();
        
        // Retrieve FCM token
        return getToken(messagingResolve, { vapidKey: process.env.REACT_APP_VAPID_KEY, serviceWorkerRegistration })
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
        return null;
    }
};

export const onForegroundMessage = async () => {
  // Wait for the messaging promise to resolve
  const messagingResolve = await messagingPromise;

  // Check if messaging is initialized
  if (!messagingResolve) {
    console.log('Messaging is not initialized');
    return null;
  }
  // Return a promise that resolves when a message is received
  return new Promise((resolve) => {
    // Attach a listener for foreground messages
    onMessage(messagingResolve, (payload) => {
      console.log('onMessage. ', payload);
      // Resolve the promise with the payload when a message is received
      resolve(payload);
    });
  });
}


