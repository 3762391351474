import React from 'react';
import { Link } from "react-router-dom";
import Navigation from './Navigation';
import classes from './MainHeader.module.css';
import mainclasses from '../../style.module.css';


const MainHeader = (props) => {
  return (
    <header className={classes.stickyheader}>
      <div className={`mt-1 ${mainclasses.noprint}`}>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-4">
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 text-center p-0">
            <picture>
              <source media="(max-width: 991px)" srcSet={process.env.PUBLIC_URL + "/images/mob_logo.png"} />
              <Link to="/"><img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" className={`${classes.logo_img} imgResponsive`} />
              </Link>
            </picture>
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-4">
          </div>
        </div>
      </div>
      <Navigation />
    </header>
  );
};

export default MainHeader;
