import { useState, useEffect, useCallback } from "react";
import ResultCard from "./ResultCard";
import DatePicker from "react-datepicker";
import { format,subMonths } from "date-fns";
import Loader from "../UI/Loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./TodayResult.module.css";
import oldclasses from "./OldResult.module.css";
import API from "../../Api";
import { Helmet } from "react-helmet";

const OldResult = () => {
  let dateObj = new Date();
  dateObj.setDate(dateObj.getDate() - 1);
  const [lotteryData, setLotteryData] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [yesterdayDate, setYesterdayDate] = useState(dateObj);

  const fetchLotteryList = useCallback(async () => {
    setIsLoading(true);
    try {
      let selectedDate = format(yesterdayDate, "yyyy-MM-dd");
      API.post(`get-Lottery-on-drawdate`, { drawdate: selectedDate })
        .then((res) => {
          setLotteryData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [yesterdayDate]);
  useEffect(() => {
    fetchLotteryList();
  }, [fetchLotteryList]);
  let content = "";
  if (lotteryData.data.length > 0) {
    content = <ResultCard LotteryDetails={lotteryData} callFrom="OLD"/>;
  }
  if (isLoading) {
    content = <Loader />;
  }
  return (
    <>
       <Helmet>
        <title>Rajshree Lottery Result - Old Results | Rajshree Lottery</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Here you can check old rajshree lottery results. Get old rajshree lottery results in jpg and pdf. verify your tickets instantly." />
      </Helmet>
      <div className="container">
        <div className="mt-5">
          <div className="row">
            <div className="col-md-12 text-center p-0">
              <h2 className={classes.result_heading}>Old Results</h2>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <span className={oldclasses.date_head}>Select Draw Date</span>
              <DatePicker
                className={`form-control ${oldclasses.date_result}`}
                selected={yesterdayDate}
                onChange={(date) => setYesterdayDate(date)}
                dateFormat="dd/MM/yyyy"
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                minDate= {subMonths(new Date(), 3)}
                maxDate={dateObj}
              />
            </div>
          </div>
        </div>
        {content}
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-5 mx-auto text-center">
            <h3 className={classes.result_heading1}>
              Rajshree Lottery Results | Check Old Rajshree Lottery Results
            </h3>
            <p className={classes.para}>
              Here, you can check old rajshree lottery results. Get old rajshree
              lottery results in jpg and pdf format.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldResult;
