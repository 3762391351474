import classes from './Input.module.css';

import React from 'react';

const Input = React.forwardRef((props, ref) => {
  return (
    <div className={`${classes.input}`}>
      <input ref={ref} {...props.input} onChange={props.onChange} onKeyDown={props.onKeyDown} />
    </div>
  );
});

export default Input;