import { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import mainclasses from '../../style.module.css';
const ClaimForm = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Claim | Rajshree Lottery</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Here you can get claim form. Fill out and claim your winning amount. Any prize amount exceeding Rs 10,000 will be routed through government procedure." />
            </Helmet>
            <div className="flex-fill">

                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <h2 className={mainclasses.head}>Claims</h2>
                            <div className="mt-4">
                                <span><Link to="/mizoram-form" className={mainclasses.linkhead}>Govt. Of Mizoram Claim Form </Link></span>
                            </div>
                            <div className="mt-4">
                                <span><Link to="/goa-form" className={mainclasses.linkhead}>Govt. Of Goa Claim Form</Link></span>
                            </div>
                            <div className="mt-4">
                                <span><Link to="#" className={mainclasses.linkhead}>Govt. Of Maharashtra Claim Form</Link></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 mx-auto">
                            <p className={mainclasses.para}>Any prize winnings up to Rs.10,000 can be claimed from any of the retail counter near you or you can send your ticket to us to get your account credited with the prize winning amount. Any prize amount exceeding Rs 10,000 will be routed through government procedure, for this you can click on below format.</p>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
}
export default ClaimForm;