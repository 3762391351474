import { useContext } from 'react';
import classes from './Footer.module.css';
import {Link} from "react-router-dom";
import mainclasses from '../../style.module.css';
import { AppContext } from '../Context/AppContext';
import useRetainQueryParams from '../Hooks/useRetainQueryParams';


const Footer = (props) => {
    const { appendQueryParams } = useRetainQueryParams();
    const { platform, appType } = useContext(AppContext);
    const shouldHideLiveMenu = platform === "APP" && appType === "STORE";

    return (
        <footer className={`${classes.footer} ${mainclasses.noprint}`}>
            <div className="container-fluid mt-5 noprint">
                <div className="row">
                    <div className="col-md-10 mx-auto text-center">
                        <div>
                            <ul>
                                <li><Link to={appendQueryParams('/')}>Home</Link></li>
                                <li><Link to={appendQueryParams('/today-result')}>Today Results</Link></li>
                                <li><Link to={appendQueryParams('/old-result')}>Old Results</Link></li>
                                {!shouldHideLiveMenu && <li><Link to={appendQueryParams('/live')}>Live Draw</Link></li>}
                                <li><Link to={appendQueryParams('/claims')}>Claims</Link></li>
                                {/* <li><Link to="/verify-ticket">Verify Ticket</Link></li> */}
                                <li><Link to={appendQueryParams('/sitemap')}>Site Map</Link></li>
                                <li><Link to={appendQueryParams('/')}>Disclaimer</Link></li>
                                <li><Link to={appendQueryParams('/contact')}>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div>
                            <p>All Rights Reserved</p>
                        </div>
                        <div className="mb-2">
                            <a className="p-1" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Rajshreelottery"><i className="fa fa-facebook-square"></i></a>
                            <a className="p-1" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/rajshreelottery/"><i className="fa fa-instagram"></i></a>
                            <a className="p-1" target="_blank" rel="noopener noreferrer" href="https://twitter.com/rajshreelottery"><i className="fa fa-twitter-square"></i></a>
                            <a className="p-1" target="_blank" rel="noopener noreferrer" href="https://t.me/rajshreelottery "><i className="fa fa-telegram"></i></a>
                            <a className="p-1" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/c/RajshreeLottery "><i className="fa fa-youtube-play"></i></a>
                            <a id="back2Top" className={classes.back2Top} title="Back to top" href="# "><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;