import { useContext } from "react";
import {Link} from "react-router-dom";
import mainclasses from '../../style.module.css';
import classes from './SiteMap.module.css';
import { AppContext } from "../Context/AppContext";
const SiteMap = () => {
    const { platform, appType } = useContext(AppContext);
    const shouldHideLiveMenu = platform === "APP" && appType === "STORE";
  
    return (
        <>
            <div className="flex-fill">
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-8 mx-auto text-center">
                            <span className={mainclasses.head}>Site Map</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mx-auto mt-5">
                            <ul className={`list-group ${classes.ul_site}`}>
                                <li className="list-group-item"><Link to="/">Home</Link></li>
                                <li className="list-group-item"><Link to="/today-result">Today Results</Link></li>
                                <li className="list-group-item"><Link to="/old-result">Old Results</Link></li>
                                {!shouldHideLiveMenu && <li className="list-group-item"><Link to="/live">Live Draw</Link></li>}
                                <li className="list-group-item"><Link to="/claims">Claims</Link></li>
                                {/* <li className="list-group-item"><Link to="/verify-ticket">Verify Ticket</Link></li> */}
                                <li className="list-group-item"><Link to="/news-updates">News</Link></li>
                                <li className="list-group-item"><Link to="/act-rules">Acts & Rules</Link></li>
                                <li className="list-group-item"><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SiteMap;