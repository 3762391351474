import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import API from "../../Api";
import Swal from "sweetalert2";
import classes from "./Contact.module.css";

const ContactUs = () => {
    const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,

    });
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data, event) => {
        event.preventDefault();
        reset();
        API.post(`contact`, data)
            .then(res => {
                Toast.fire({
                    icon: 'success',
                    title: res.data.message
                });
            }).catch(err => {
                console.log(err)
            });
    }
    return (
        <>
            <Helmet>
                <title>Contact | Rajshree Lottery</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="description"
                    content="Fill the form to contact us. Visit our social media channels to get latest updates of rajshree lottery."
                />
            </Helmet>
            <div className="flex-fill">
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <h2 className="head">Contact Us</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="row">
                                <div className="col-lg-5 mt-4 shadow p-4">
                                    <form action=" " onSubmit={handleSubmit(onSubmit)}>
                                        <div className={`form-group  ${classes.inptcontdiv}`}>
                                            <i className="fa fa-user-o" />
                                            <input
                                                type="text"
                                                className={`form-control  ${classes.inptcont}`}
                                                placeholder="Full Name *"
                                                maxLength="50"
                                                {...register('username', { required: true, pattern: /^[A-Za-z ]+$/i })}
                                                name="username"
                                                id="username"
                                                autoComplete="off"
                                            />
                                            {errors.username && <p className={classes.error_p}>Please enter a valid name.</p>}
                                        </div>
                                        <div className={`form-group  ${classes.inptcontdiv}`}>
                                            <i className="fa fa-phone" />
                                            <input
                                                type="text"
                                                className={`form-control  ${classes.inptcont}`}
                                                placeholder="Contact No *"
                                                name="mobile"
                                                id="mobile"
                                                maxLength="10"
                                                {...register('mobile', { required: true, maxLength: 10, minLength: 10, pattern: /^[0-9\b]+$/ })}
                                                autoComplete="off"
                                            />
                                            {errors.mobile && <p className={classes.error_p}>Enter a valid Mobile number.</p>}
                                        </div>
                                        <div className={`form-group  ${classes.inptcontdiv}`}>
                                            <i className="fa fa-envelope-o" />
                                            <input
                                                type="email"
                                                className={`form-control  ${classes.inptcont}`}
                                                placeholder="Email"
                                                name="email"
                                                id="email"
                                                {...register('email', { required: true })}
                                                autoComplete="off"
                                            />
                                        </div>
                                        {errors.email && <p className={classes.error_p}>Email is required.</p>}
                                        <div className={`form-group  ${classes.inptcontdiv}`}>
                                            <i className="fa fa-commenting-o" />
                                            <textarea
                                                className={`form-control  ${classes.inptconttextarea}`}
                                                placeholder="Message *"
                                                {...register('message', { required: true })}
                                                rows="4"
                                                minLength="10"
                                                maxLength="250"
                                                id="message"
                                                name="message"
                                            />
                                            {errors.message && <p className={classes.error_p}>Message is required.</p>}
                                        </div>
                                        <div className="form-group m-0">
                                            <label>Preferred communication mode :</label>
                                        </div>
                                        <div className="form-group form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="phone"
                                                    value="Phone"
                                                    {...register('prefferd_contactby', { required: true })}
                                                    name="prefferd_contactby"
                                                    required
                                                />
                                                {errors.prefferd_contactby && <p className={classes.error_p}>This is required.</p>}
                                                Phone
                                            </label>
                                        </div>
                                        <div className="form-group form-check-inline">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="mail"
                                                    value="Email"
                                                    {...register('prefferd_contactby', { required: true })}
                                                    name="prefferd_contactby"
                                                    required
                                                />
                                                {errors.prefferd_contactby && <p className={classes.error_p}>This is required.</p>}
                                                Email
                                            </label>
                                        </div>
                                        <button type="submit" className={`btn ${classes.btncont}`}>
                                            Submit
                                        </button>
                                    </form>
                                </div>

                                <div className={`col-lg-6 ml-lg-auto ${classes.socicon}`}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.facebook.com/Rajshreelottery"
                                                className={classes.fbicon}
                                            >
                                                <i className="fa fa-facebook-square" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.instagram.com/rajshreelottery/"
                                                className={classes.instaicon}
                                            >
                                                <i className="fa fa-instagram" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://twitter.com/rajshreelottery"
                                                className={classes.twiticon}
                                            >
                                                <i className="fa fa-twitter-square" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://t.me/rajshreelottery"
                                                className={classes.teleicon}
                                            >
                                                <i className="fa fa-telegram" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
