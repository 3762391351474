import { Fragment } from "react"
import { Link } from "react-router-dom";
import classes from './ClaimForm.module.css';
import mainclasses from './../../style.module.css';
import { format } from "date-fns";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const GoaFormSubmit = (props) => {
    const printdata = () => {
        window.print()
    }
    const pdfGenerate = () => {
        window.scrollTo(0, 0);
        const divToPrint = document.querySelector('#printTable');
        html2canvas(divToPrint).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF('pt', 'mm', 'a4');
            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight + 20);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 20);
                heightLeft -= pageHeight;
            }
            doc.save('GoaForm.pdf');
        });
    };
    return (
        <Fragment>
            <div id="printTable" className="container">
                <div className="row mt-4">
                    <div className="col-12 text-center">
                        <Link to="/">
                            <img
                                src={process.env.PUBLIC_URL + "images/Govt_of_Goa.png"}
                                className="goa_logo" alt="Govt_of_Goa"
                            />
                        </Link>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <h3 className={classes.form_head}>
                            Claim Application for prize of the goa state lotteries
                        </h3>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-10 col-8">
                        <p className={mainclasses.para1}>To,</p>
                        <p className={mainclasses.para1}>The Director</p>
                        <p className={mainclasses.para1}>Director Of The Small Saving & Lotteries.</p>
                        <p className={mainclasses.para1}>Government Of Goa,</p>
                        <p className={mainclasses.para1}>Serra Building Altinho,</p>
                        <p className={mainclasses.para1}>Panji - Goa</p>
                    </div>
                    <div className={`col-lg-2 col-4 ${classes.uplimg_div}`}>
                        <img id="output" src={props.goaFormData.photo} alt="winners" className={classes.uploadImgPreview} />
                        <label className={classes.uplimg_text}>Winner Photo </label>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-12 text-center">
                        <p className={mainclasses.para1}>
                            Sub: Claim Application For Prize Of The Goa State Lotteries
                        </p>
                    </div>
                </div>

                <div className=" mt-5">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p className={`${mainclasses.para1} pl-2`}>CLAIMED DETAILS:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Full Name Of prize Winner (Beneficiary) *</label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input
                                type="text"
                                placeholder="Full Name Of The prize Winner (Beneficiary) *"
                                value={props.goaFormData.name}
                                className={`${classes.form_submit_inp} `}
                                name="winner_name"
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Mailing Address </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input
                                type="text"
                                placeholder="Address 1 *"
                                className={`${classes.form_submit_inp} `}
                                name="address1"
                                value={props.goaFormData.address1}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input
                                type="text"
                                placeholder="Address 2 *"
                                className={`${classes.form_submit_inp} `}
                                name="address2"
                                value={props.goaFormData.address2}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Father's/Husband's Name </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input
                                type="text"
                                placeholder="Father's/Husband's Name *"
                                className={`${classes.form_submit_inp} `}
                                name="fath_husb_name"
                                value={props.goaFormData.fname}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Contact No (compulsary) </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input type="text" placeholder="My Contact No is *"
                                className={`${classes.form_submit_inp} `} name="cont_no" value={props.goaFormData.mobile} readOnly />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Date of Birth </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                placeholder="Date Of Birth *"
                                className={`${classes.form_submit_inp} `}
                                type="text"
                                id="date"
                                name="dob"
                                value={format(new Date(props.goaFormData.dob), 'dd/MM/yyyy')}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Profession / Nature of Business </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                type="text"
                                placeholder="Nature Of Bussiness *"
                                className={`${classes.form_submit_inp}`}
                                name="bussiness"
                                value={props.goaFormData.natureof_business}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Name Of The Organization </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input
                                type="text"
                                placeholder="Name Of The Organization *"
                                className={`${classes.form_submit_inp} `}
                                name="organ_name"
                                value={props.goaFormData.organisation_name}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">PAN (if any) </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className="col-lg-8 col-8 mt-4">
                            <input
                                type="text"
                                placeholder="Pan If Any *"
                                className={`${classes.form_submit_inp} `}
                                name="pan_no"
                                value={props.goaFormData.panno}
                                readOnly
                            />
                        </div>
                    </div>


                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Bank Account No. of prize winner (Beneficiary) </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Bank A/c No. of Prize Winner (Beneficiary) *"
                                className={`${classes.form_submit_inp} `}
                                name="bank_acno"
                                value={props.goaFormData.bank_account_no}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">IFSC Code</label><label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                type="text"
                                placeholder="IFSC Code *"
                                className={`${classes.form_submit_inp} `}
                                name="ifsc_code"
                                value={props.goaFormData.ifsc_code}
                                readOnly
                            />
                        </div>
                    </div>


                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Name of Bank, Branch & Address </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>

                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Bank Name *"
                                className={`${classes.form_submit_inp} `}
                                name="bank_name"
                                value={props.goaFormData.bank_name}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                        </div>
                        <div className="col-lg-8 col-8 ">
                            <div className={`mt-4`}>
                                <input
                                    type="text"
                                    placeholder="Branch *"
                                    className={`${classes.form_submit_inp} `}
                                    name="branch"
                                    value={props.goaFormData.bank_branch}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                    <div className=" mt-5">
                        <p className={`${mainclasses.para1} pl-2 `}>WINNING TICKET DETAILS:</p>
                    </div>



                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Winning Ticket Transaction No * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Winning Ticket Transaction No *"
                                className={`${classes.form_submit_inp} `}
                                name="win_ticketno"
                                value={props.goaFormData.transno}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Draw No * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Draw No *"
                                className={`${classes.form_submit_inp} `}
                                name="draw_no"
                                value={props.goaFormData.drawno}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Date Of Draw * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                placeholder="Date Of Draw *"
                                className={`${classes.form_submit_inp} `}
                                type="text"
                                id=""
                                name="draw_date"
                                value={format(new Date(props.goaFormData.drawdate), 'dd/MM/yyyy')}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Time * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                placeholder="Time *"
                                className={`${classes.form_submit_inp} `}
                                type="text"
                                id=""
                                name="draw_time"
                                value={props.goaFormData.drawtime}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Winning Numbers * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Winning Number *"
                                className={`${classes.form_submit_inp} `}
                                name="winn_no"
                                value={props.goaFormData.ticketno}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Name Of Lottery * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                type="text"
                                placeholder="Name Of Lottery *"
                                className={`${classes.form_submit_inp} `}
                                name="lott_name"
                                value={props.goaFormData.lotteryname}
                                readOnly
                            />
                        </div>
                    </div>



                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Rank Of Prize </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Rank Of Prize *"
                                className={`${classes.form_submit_inp} `}
                                name="rank_prize"
                                value={props.goaFormData.prizerank}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Prize Amount </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                type="text"
                                placeholder="Prize Amount *"
                                className={`${classes.form_submit_inp} `}
                                name="prize_amt"
                                value={props.goaFormData.prizeamt}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Name Of Retailer (Point Of Sale) * </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8  mt-4`}>
                            <input
                                type="text"
                                placeholder="Name Of Retailer (Point Of Sale) *"
                                className={`${classes.form_submit_inp} `}
                                name="no_retail"
                                value={props.goaFormData.retname}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-lg-4 col-4 mt-4 ${classes.label_div}`}>
                            <label className="font-weight-bold mt-2">Prize Amount </label>
                            <label className="font-weight-bold mt-2">:</label>
                        </div>
                        <div className={`col-lg-8 col-8 mt-4`}>
                            <input
                                type="text"
                                placeholder="Retailer Code *"
                                className={`${classes.form_submit_inp} `}
                                name="retail_code"
                                value={props.goaFormData.retcode}
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-1">
                        <p className={mainclasses.para1}>Enclose: </p>
                    </div>
                    <div className="col-lg-10">
                        <p className={mainclasses.para1}>1. Original Price - Winning Ticket</p>
                        <p className={mainclasses.para1}>2. Proof Of Date Of Birth </p>
                        <p className={mainclasses.para1}>3. Proof Of Address </p>
                        <p className={mainclasses.para1}>4. Pan Card Photo Copy </p>
                        <p className={mainclasses.para1}>
                            5. 4 Passport Size Photography & Affidavit Duly Attested by
                            Notary (On Rs. 20/- Stamp Paper)
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-9 ml-auto text-center mt-5">
                        <p>&nbsp;</p>
                    </div>
                    <div className={`${classes.div3_form} col-lg-3 ml-auto text-center mt-5`}>
                        <p className={classes.sign_text}>Signature</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 text-center mt-5">
                        <p className={mainclasses.para1}>RECEIPT: </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mt-4">
                        <input
                            type="text"
                            className={`${classes.form_submit_inp} `}
                            placeholder="Recipient's Name"
                            name="rece_name"
                            readOnly
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mt-4">
                        <p className={classes.receipt_para}>
                            Received with thanks from, The Director, Goa State Lotteries,
                            Panaji Rs.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8 mt-4">
                        <input
                            type="text"
                            placeholder="(Rupees"
                            className={`${classes.form_submit_inp} `}
                            name="rece_rs"
                            value={props.goaFormData.prizeamt}
                            readOnly
                        />
                    </div>
                    <div className="col-lg-4 mt-4">
                        <p className={classes.receipt_para}>
                            Only) towards my claim of ticket
                        </p>
                    </div>
                </div>


                <div className="row">
                    <div className={`col-lg-5 mt-4 ${classes.div5_form}`}>
                        <input
                            type="text"
                            placeholder="Transaction No"
                            className={`${classes.form_submit_inp} `}
                            name="tran_no"
                            value={props.goaFormData.transno}
                            readOnly
                        />
                    </div>
                    <div className={`col-lg-1 mt-4 ${classes.div1_form}`}>
                        <p className={classes.receipt_para}>Of</p>
                    </div>
                    <div className={`col-lg-4 mt-4 ${classes.div4_form}`}>
                        <input
                            type="text"
                            className={`${classes.form_submit_inp} `}
                            name="tran_no1"
                            readOnly
                        />
                    </div>
                    <div className={`col-lg-2 mt-4 ${classes.div2_form}`}>
                        <p className={classes.receipt_para}>Lottery Draw</p>
                    </div>
                </div>


                <div className="row">
                    <div className={`col-lg-5 mt-4 ${classes.div5_form}`}>
                        <input
                            type="text"
                            placeholder="No."
                            className={`${classes.form_submit_inp} `}
                            name="lott_no"
                            value={props.goaFormData.lotcode}
                            readOnly
                        />
                    </div>
                    <div className={`col-lg-7 mt-4 ${classes.div7_form}`}>
                        <input
                            placeholder="Held On"
                            className={`${classes.form_submit_inp} `}
                            type="date"
                            id=""
                            name="held_date"
                            value={props.goaFormData.drawdate}
                            readOnly
                        />
                    </div>
                </div>

                <div className="row  mt-5">
                    <div className={`col-lg-8 col-8 mt-5 ${classes.div8_form}`}>
                        <input type="text" placeholder="Name" className={`${classes.form_submit_inp} `} name="reve_name" readOnly />
                        <input type="text" placeholder="Address 1" className={`${classes.form_submit_inp}  mt-4`} name="reve_add1" readOnly />
                        <input type="text" placeholder="Address 2" className={`${classes.form_submit_inp}  mt-4`} name="reve_add2" readOnly />
                    </div>
                    <div className={`${classes.div4_form} mt-5`} >
                        <div className={`col-lg-6 ${classes.revn_div}`}>
                            <p className={`${classes.receipt_para}`}>Revenue Stamp</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={mainclasses.noprint}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center mt-5">
                            <button
                                className={`btn ${classes.form_btn} mr-2`}
                                onClick={printdata}
                            >
                                print
                            </button>
                            <button className={`btn ${classes.form_btn}`} onClick={pdfGenerate}>
                                save as pdf
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default GoaFormSubmit;