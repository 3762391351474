import { useEffect } from 'react';
import { getFirebaseToken,onForegroundMessage } from '../../firebase';
import API from '../../Api';
const NotificationHandler = () => {
    const subscribeToTopic = (data) => {
        API.post(`subscribe-to-topic`, data)
            .then(res => {
                console.log(res.data.message)
            }).catch(err => {
                console.log(err)
            });
    }
    useEffect(() => {
        const handleToken = async () => {
            try {
                const token = await getFirebaseToken();
                console.log('FCM token:', token);
                subscribeToTopic({"token":token});

            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };
        handleToken();
        
        const handleForegroundMessage = async () => {
            try {
                const payload = await onForegroundMessage();
                try {
                    console.log('Message received. ', payload);
                    const title = payload.data.title;
                    const notificationOptions = {
                        body: payload.data.message,
                        icon: "https://myrajshree.com/images/icon.png",
                        image: payload.data.imgUrl,
                        click_action:payload.data.imgDeepLink,
                        data: {
                            url: payload.data.imgDeepLink,
                            click_action:payload.data.imgDeepLink
                        },                    
                        actions: [{
                            action: 'Yes',
                            title: 'Go'
                        }]
                    };                
                    console.log("title ", title, " ", payload.data.message);
                    const registration = await navigator.serviceWorker.ready;
                    return registration.showNotification(title, notificationOptions).onclick = function (event) {
                        console.log(event);
                        // console.log(payload.notification.click_action);
                        if(payload && payload.notification &&  payload.notification.click_action &&  payload.notification.click_action.length > 0)
                        {
                          window.open(payload.notification.click_action, '_blank');
                        }
                        this.close();
                      };
                  }
                  catch (err) {
                    console.log('Caught error: ', err);
                  }
                // Handle the incoming message, e.g., display a notification or update UI
                console.log('Foreground message received:', payload);
            } catch (error) {
                console.error('Error handling foreground message:', error);
            }
        };
        handleForegroundMessage();
        return () => {
            console.log('Notification component unmounted');
        };
    }, []);

    // Return null or empty fragment
    return null;
}

export default NotificationHandler;
