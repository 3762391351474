import { Helmet } from "react-helmet";
import styles from '../../style.module.css';

const News = () => {
  const newsItems = [
    {
      title: "Maharashtra's Overnight Millionaire!",
      link: "https://www.mid-day.com/brand-media/article/maharashtra-overnight-millionaire-23270761",
      description: "Article about the man who became overnight millionaire in Maharashtra's Sangli."
    },
    {
      title: "राजश्री लॉटरी ने कालिदासजींचे स्वप्न पूर्ण केले | New Paper - Kesari 8Jan2022",
      link: "files/Kesari_Sangli-Kolhapur8122.pdf",
      description: "भारतात ' द लॉटरी (नियमन)  कायदा १९९८ ' अंतर्गत, केवल राज्य सरकारांना लॉटरी चलावण्याची परवानगी आहे आणि राजश्री लॉटरी १९८० मधे सुरु झाली होती, त्या अंतर्गत विविध राज्य सरकारचे परवाने आहेत. ज्या अंतर्गत राजश्री लॉटरी टिकिटांचे वितरण आणि जाहिरात केली जाते.  या आधीही राजश्री लॉटरी ने कालिदासजीं सारख्या अनेकांना आणि त्यांच्या कुटुंबाला रोजगार देऊन मदत केली आहे."
    },
    {
      title: "लॉटरी स्टॉल धारक बनला व्यवसाहिक - राजश्री लॉटरी | News Paper - Deshdoot 2Feb2022",
      link: "files/deshdoot.pdf",
      description: "छोट्या स्टॉलधारक ते व्यवसाहिक  पर्यन्तच्या प्रवासात गजानन यांना राजश्री लॉटरी चे सहकार्य लाभले."
    },
    {
      title: "Goa Launches 4 New Lotteries on High Public Demand",
      link: "https://deshonnati.digitaledition.in/2913278/Akola-Main/05-Akola-Main#page/2/1",
      description: "Minister Dr. Pramod Sawant along with Dilip Hamraskar, Sushma Kamat and Nitin Jain inaugurated the launch of 4 Rajshree lottery draw on high demand from customers."
    },
    {
      title: "Chief Minister Dr. Pramod Sawant Inaugurated the Launch of 4 New Lotteries in The Goa State",
      link: `${process.env.PUBLIC_URL}files/Kesari_01 Dec.Goa Lottery News Kesari Sangli-Kolhapur Edition.pdf`,
      description: "Dilip Humraskar, Sushma Kamat and Nitin Jain along with Dr. Pramod Sawant inaugurated the launch of 4 Rajshree lottery draw."
    }
  ];

  return (
    <>
      <Helmet>
        <title>News &amp; Updates | Rajshree Lottery</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Here you can checkout latest news and updates of rajshree lottery. Get latest rajshree lottery news articles." />
      </Helmet>
      <div className={`flex-fill ${styles.containerFluid}`}>
        <div className={`container-fluid mt-5 ${styles.row}`}>
          <div className="col-lg-8 mx-auto">
            <h2 className={styles.head}>News & Updates</h2>
          </div>
        </div>
        <div className={`container-fluid mt-2 ${styles.row}`}>
          {newsItems.map((item, index) => (
            <div key={index} className="col-md-8 mx-auto">
              <div className={`row ${styles.row}`}>
                <div className={`col-md-12 mt-4 ${styles.col}`}>
                  <p>{item.title}</p>
                  <a target="_blank" rel="noopener noreferrer" href={item.link}>
                    <span>{item.description}</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default News;
