
import { Helmet } from "react-helmet";
import classes from "./Acts.module.css";
import mainclasses from "../../style.module.css";

const Acts = () => {
  const actRuleLinks = [
    {
      title: "The Lotteries (Regulation) Rules, 2010",
      url: process.env.PUBLIC_URL + "files/lotteries_rules_2010.pdf",
    },
    {
      title: "Lotteries (Regulation) Act, 1998",
      url: process.env.PUBLIC_URL + "files/lotteries_act_1998.pdf",
    },
    {
      title: "Mizoram Lotteries (regulation) Rules, 2019",
      url: process.env.PUBLIC_URL + "files/MIZORAM_RULES_2019_(1).pdf",
    },
    {
      title: "The Arunachal Pradesh Lotteries (Regulations) Rules 2013",
      url: process.env.PUBLIC_URL + "files/ARUNACHAL_RULES_2013.pdf",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Act & Rules | Rajshree Lottery</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Checkout Government act & rules. The Lotteries (Regulation) Rules, 2010. Lotteries (Regulation) Act, 1998. Mizoram Lotteries (regulation) Rules, 2019.The Arunachal Pradesh Lotteries (Regulations) Rules 2013"
        />
      </Helmet>
      <div className="flex-fill">
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h2 className={mainclasses.head}>Act & Rules</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-8 mx-auto">
              {actRuleLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  className={`${classes.actrulehead} mt-4`}
                  download
                >
                  <p>{link.title}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Acts;
