import React, { useEffect, useState } from "react";
import API from "../../Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes,} from "@fortawesome/free-solid-svg-icons";
import "./ViewResult.css";


const ViewResult = ({ drawdate, lotcode, lotname, hideShow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageData,setImageData] = useState("");


  const downloadResultImage = (drawdate, lotcode, lotname) => {
    setIsLoading(true);
    let uri = "v2/get-result-img/" + drawdate + "/" + lotcode;
    try {
      API.get(uri, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], { type: "image/png" });
          setImageData(URL.createObjectURL(blob));
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    downloadResultImage(drawdate, lotcode, lotname);
  }, [drawdate, lotcode, lotname]);


  return (
    <>
      <div className="Example__container">
      {isLoading ? <div>Loading Image...</div> : ""}
        <button className="close-button" onClick={hideShow}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {imageData && (
          <div className="Example__container__document">
            <img className="result-image" src={imageData} alt=""/>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewResult;
