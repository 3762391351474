import { useLocation } from 'react-router-dom';
const useQueryParams = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    return {
        queryPlatform: queryParams.get('platform'),
        queryAppType: queryParams.get('app_type'),
    };
}
export default useQueryParams;
