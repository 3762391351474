import { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import classes from './ClaimForm.module.css';
import mainclasses from './../../style.module.css';
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import API from "../../Api";
import Swal from "sweetalert2";
import { Controller, useForm } from 'react-hook-form';
import MizoramFormSubmit from './MizoramFormSubmit';
let formdataArr=[];
const MizoramForm = (props) => {
    const { control, register, handleSubmit, formState: { errors }, } = useForm();
    const [mizoSubmitForm, setMizoSubmitForm] = useState(true);
    const [image, setImage] = useState({ preview: "images/user.png", photoname: "", photo_type: "" });
    const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,

    });

    const handleChangeImage = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setImage({
                preview: reader.result,
                photoname: e.target.files[0].name,
                photo_type: e.target.files[0].type
            });
        };
        reader.readAsDataURL(file);
        console.log(image)
    }

    const onSubmit = (data, event) => {
       let param={
            govtname: "Govt Of Mizoram",
            dob: "",
            retcode: "",
            transno: "",
            retname: "",
            drawtime: "",
            lotcode: "",
            ticketno: data.ticketno,
            address1: data.address1,
            address2: data.address2,
            bank_account_no: data.bank_account_no,
            bank_branch: data.bank_branch,
            bank_name: data.bank_name,
            drawdate: format(data.drawdate, 'yyyy-MM-dd'),
            drawno: data.drawno,
            fname: data.fname,
            ifsc_code: data.ifsc_code,
            lotteryname: data.lotteryname,
            mobile: data.mobile,
            name: data.name,
            natureof_business: data.natureof_business,
            organisation_name: data.organisation_name,
            panno: data.panno,
            prizeamt: data.prizeamt,
            prizerank: data.prizerank,
            profession_bussiness: data.profession_bussiness,
            profession_service: data.profession_service,
            photo: image.preview,
            photoname: image.photoname,
            photo_type: image.photo_type
        };
        console.log((param));
        formdataArr=param;
        API.post(`save-claimform`, param)
            .then(res => {
                if (res.data.status === 'error') {
                    setMizoSubmitForm(true);
                    Toast.fire({
                        icon: 'success',
                        title: res.data.message
                    });
                    return false;
                }else{
                    Toast.fire({
                        icon: 'success',
                        title: res.data.message
                    });
                    setMizoSubmitForm(false)
                    setImage({ preview: "", photoname: "", photo_type: "" })
                }
            }).catch(err => {
                console.log(err)
                setMizoSubmitForm(true)
            });
        event.preventDefault();
      
    };
    console.log(formdataArr)
    return (
        <Fragment>
            {mizoSubmitForm ?
                <div className="container flex-fill">
                    <div className="mt-5">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <Link to="/">
                                    <img
                                        alt="GovtofMizoram"
                                        src={
                                            process.env.PUBLIC_URL + "images/Govt_of_Mizoram.png"
                                        }
                                        className={classes.mizo_logo}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className={classes.form_head}>
                                    Application for Lottery prize claim
                                </h3>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mt-5">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="col-lg-12 mt-5 ml-2">
                                        <p className={mainclasses.para1}>To,</p>
                                        <p className={mainclasses.para1}>The Director</p>
                                        <p className={mainclasses.para1}>
                                            Institutional Finance & State Lottery
                                        </p>
                                        <p className={mainclasses.para1}>
                                            Government Of Mizoram, Tuikhuahtlang,
                                        </p>
                                        <p className={mainclasses.para1}>Aizawl - 796001, Mizoram.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <div className="row">
                                        <div className={`col-lg-6 col-6 ${classes.uplimg_div}`}>
                                            <img id="photo" src={image.preview} alt={image.photoname} className={classes.uploadImgPreview}/>
                                            <input id="photoname" accept=".jpef, .png, .jpg" type="file" defaultValue={image.photoname} className={classes.upl_inpt} onChange={handleChangeImage} />
                                            <label htmlFor="photoname" className={classes.uplimg_text}>
                                                Upload Photo <i className="fa fa-camera" />{" "}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-5">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <p className={mainclasses.para1}>
                                        I Herewith submit my prize winning ticket for your kind
                                        consideration
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-5">
                            <div className="row">
                                <div className="col-lg-12 mt-4">
                                    <input
                                        placeholder="Prize Winning Ticket No *"
                                        className={`form-control  ${classes.form_inp}`}
                                        {...register("ticketno", {
                                            required: true,
                                            pattern: /^[A-Za-z0-9\- ]+$/i
                                        })}
                                    />
                                    {errors?.ticketno?.type === "required" && <p className={classes.error_p}>Ticket Number is required</p>}
                                    {errors?.ticketno?.type === "pattern" && (
                                        <p className={classes.error_p}>Alpha Numeric characters allow only</p>
                                    )}
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <input
                                        placeholder="Lottery *"
                                        className={`form-control  ${classes.form_inp}`}
                                        id="lotteryname"
                                        {...register("lotteryname", {
                                            required: true,
                                            pattern: /^[A-Za-z0-9\- ]+$/i
                                        })}
                                    />
                                    {errors.lotteryname && <p className={classes.error_p}>Please Enter Valid Lottery Name!</p>}
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                placeholder="Draw No *"
                                                className={`form-control  ${classes.form_inp}`}
                                                id="drawno"
                                                {...register("drawno", {
                                                    required: true,
                                                    pattern: /^[0-9]+$/i
                                                })}
                                            />
                                            {errors.drawno && <p className={classes.error_p}>Please Enter Valid Draw Number!</p>}
                                        </div>
                                        <div className="col-lg-2 mt-4">
                                            <label className={`${classes.form_label} ml-2 mr-3`}>Held On </label>
                                        </div>
                                        <div className="col-lg-4 mt-4">
                                            <Controller
                                                control={control}
                                                name='drawdate'
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText='Select date'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        dateFormat="dd/MM/yyyy"
                                                        className={`form-control ${classes.form_inp}  ${classes.form_datepicker}`}
                                                    />
                                                )}
                                            />
                                            <i className={`fa fa-calendar  ${classes.form_icon}`} aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Full Name Of The prize Winner (Beneficiary) *"
                                        className={`form-control  ${classes.form_inp}`}
                                        {...register("name", {
                                            required: true,
                                            pattern: /^[A-Za-z ]+$/i
                                        })}
                                        id="name"
                                    />
                                    {errors.name && <p className={classes.error_p}>Please enter a valid name.</p>}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Address 1 *"
                                        className={`form-control  ${classes.form_inp}`}
                                        id="address1"
                                        {...register("address1", {
                                            required: true,
                                        })}
                                    />
                                    {errors.address1 && <p className={classes.error_p}>Address1 is required</p>}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Address 2 *"
                                        className={`form-control  ${classes.form_inp}`}
                                        {...register("address2", {
                                            required: true,
                                        })}
                                        id="address2"
                                    />
                                    {errors.address2 && <p className={classes.error_p}>Address2 is required</p>}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Father's/Husband's Name *"
                                        className={`form-control  ${classes.form_inp}`}
                                        id="fname"
                                        {...register("fname", {
                                            required: true,
                                            pattern: /^[A-Za-z ]+$/i
                                        })}
                                    />
                                    {errors.fname && <p className={classes.error_p}>Enter a  valid Fathers/Husband's Name </p>}
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">

                                        <div className="col-lg-1 mt-4 p-0">
                                            <label className={`${classes.form_label} ml-2 mr-3`}>
                                                Profession
                                            </label>
                                        </div>
                                        <div className="col-lg-5 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Bussiness *"
                                                className={`form-control  ${classes.form_inp_busin1}`}
                                                id="profession_bussiness"
                                                {...register("profession_bussiness", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9. ]+$/i
                                                })}
                                            />
                                            {errors.profession_bussiness && <p className={classes.error_p}>Please Enter Valid Bussiness Name</p>}
                                        </div>
                                        <div className="col-lg-5 mt-4 pl-lg-0">
                                            <label className={`${classes.form_label} ml-2 mr-3`}>or </label>
                                            <input
                                                type="text"
                                                placeholder="Service *"
                                                className={`form-control  ${classes.form_inp_busin1}`}
                                                id="profession_service"
                                                {...register("profession_service", {
                                                    required: true,
                                                    pattern: /^[A-Za-z ]+$/i
                                                })}
                                            />
                                            {errors.profession_service && <p className={classes.error_p}>Please Enter Valid Service Name</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Name Of The Organization *"
                                        className={`form-control  ${classes.form_inp}`}
                                        id="organisation_name"
                                        {...register("organisation_name", {
                                            required: true,
                                            pattern: /^[A-Za-z0-9. ]+$/i
                                        })}
                                    />
                                    {errors.organisation_name && <p className={classes.error_p}>Please Enter Valid Organization Name</p>}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Nature Of Bussiness *"
                                        className={`form-control  ${classes.form_inp}`}
                                        id="natureof_business"
                                        {...register("natureof_business", {
                                            required: true,
                                            pattern: /^[A-Za-z ]+$/i
                                        })}
                                    />
                                    {errors.natureof_business && <p className={classes.error_p}>Please Enter Valid Nature Of Bussiness</p>}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Rank Of Prize *"
                                        className={`form-control  ${classes.form_inp}`}
                                        id="prizerank"
                                        {...register("prizerank", {
                                            required: true,
                                            pattern: /^[0-9]+$/i
                                        })}
                                    />
                                    {errors.prizerank && <p className={classes.error_p}>Prize Rank Should be Numeric</p>}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Prize Amount *"
                                        className={`form-control  ${classes.form_inp}`}
                                        {...register("prizeamt", {
                                            required: true,
                                            pattern: /^[0-9.]+$/i
                                        })}
                                        id="prizeamt"
                                    />
                                    {errors.prizeamt && <p className={classes.error_p}>Prize Amount Should Be Numeric</p>}
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="My PAN No is *"
                                                className={`form-control  ${classes.form_inp}`}
                                                minLength="10"
                                                maxLength="10"
                                                id="panno"
                                                {...register("panno", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9]+$/i,
                                                    minLength: "10",
                                                    maxLength: "10",
                                                })}
                                            />
                                            {errors.panno && <p className={classes.error_p}>Please Enter a valid Pan Number</p>}

                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="My Contact No is *"
                                                className={`form-control  ${classes.form_inp}`}
                                                id="mobile"
                                                maxLength="10"
                                                minLength="10"
                                                {...register("mobile", {
                                                    required: true,
                                                    minLength: "10",
                                                    maxLength: "10",
                                                    pattern: /^[0-9]+$/i
                                                })}
                                            />
                                            {errors.mobile && <p className={classes.error_p}>Please Enter Valid Mobile Number</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Bank A/c No. of Prize Winner (Beneficiary) *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="bank_account_no"
                                                id="bank_account_no"
                                                {...register("bank_account_no", {
                                                    required: true,
                                                    pattern: /^[0-9]+$/i
                                                })}
                                            />
                                            {errors.bank_account_no && <p className={classes.error_p}>Please Enter Valid Bank Account Number</p>}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="IFSC Code *"
                                                className={`form-control  ${classes.form_inp}`}
                                                id="ifsc_code"
                                                {...register("ifsc_code", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9 ]+$/i,
                                                })}
                                            />
                                            {errors.ifsc_code && <p className={classes.error_p}>Please Enter valid IFSC Code</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Bank Name *"
                                                className={`form-control  ${classes.form_inp}`}
                                                id="bank_name"
                                                {...register("bank_name", {
                                                    required: true,
                                                    pattern: /^[A-Za-z ]+$/i,
                                                })}
                                            />
                                            {errors.bank_name && <p className={classes.error_p}>Please Enter valid Bank Name</p>}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Branch *"
                                                className={`form-control  ${classes.form_inp}`}
                                                id="bank_branch"
                                                {...register("bank_branch", {
                                                    required: true,
                                                    pattern: /^[A-Za-z ]+$/i,
                                                })}
                                            />
                                            {errors.bank_branch && <p className={classes.error_p}>Please Enter valid Bank Branch Name</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-1">
                                    <p className={mainclasses.para1}>Enclose: </p>
                                </div>
                                <div className="col-lg-10">
                                    <p className={mainclasses.para1}>1. Original Price - Winning Ticket</p>
                                    <p className={mainclasses.para1}>2. Full Passport Size Photo </p>
                                    <p className={mainclasses.para1}>
                                        3. Affidavit Duly Attested by Notary
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-3 ml-auto text-center mt-5">
                                    <p className={classes.sign_text}>Signature</p>
                                </div>
                            </div>

                            <div className="row">
                                <button
                                    type="submit"
                                    className={`btn ${classes.btn_cont}`}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-lg-12 mt-5">
                            <span>
                                Download Claim Form
                                <a
                                    href="files/application_for_lottery_prize_claim.pdf"
                                    download
                                >
                                    Click
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                : <MizoramFormSubmit mizoFormData={formdataArr} />
            }
        </Fragment>
    )
}

export default MizoramForm;
