import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./ClaimForm.module.css";
import mainclasses from "./../../style.module.css";
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import { format,subYears } from "date-fns";
import API from "../../Api";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import GoaFormSubmit from "./GoaFormSubmit";
let formdataArr = [];
const GoaForm = (props) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [goaSubmitForm, setGoaSubmitForm] = useState(true);
    const [image, setImage] = useState({
        preview: "images/user.png",
        photoname: "",
        photo_type: "",
    });
    const Toast = Swal.mixin({
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
    });

    const handleChangeImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setImage({
                preview: reader.result,
                photoname: e.target.files[0].name,
                photo_type: e.target.files[0].type,
            });
        };
        reader.readAsDataURL(file);
        console.log(image);
    };

    const onSubmit = (data, event) => {
        console.log(JSON.stringify(data));
        event.preventDefault();
        let param = {
            govtname: "Govt Of Goa",
            dob: format(data.dob, "yyyy-MM-dd"),
            retcode: data.retcode,
            transno: data.transno,
            retname: data.retname,
            drawtime: data.drawtime.format("hh:mm A"),
            lotcode: "",
            ticketno: data.ticketno,
            address1: data.address1,
            address2: data.address2,
            bank_account_no: data.bank_account_no,
            bank_branch: data.bank_branch,
            bank_name: data.bank_name,
            drawdate: format(data.drawdate, "yyyy-MM-dd"),
            drawno: data.drawno,
            fname: data.fname,
            ifsc_code: data.ifsc_code,
            lotteryname: data.lotteryname,
            mobile: data.mobile,
            name: data.name,
            natureof_business: data.natureof_business,
            organisation_name: data.organisation_name,
            panno: data.panno,
            prizeamt: data.prizeamt,
            prizerank: data.prizerank,
            profession_bussiness: "",
            profession_service: "",
            photo: image.preview,
            photoname: image.photoname,
            photo_type: image.photo_type,
        };
        formdataArr = param;
        console.log(JSON.stringify(param));
        API.post(`save-claimform`, param)
            .then((res) => {
                console.log(res);
                if (res.data.status === "error") {
                    setGoaSubmitForm(true);
                    Toast.fire({
                        icon: "success",
                        title: res.data.message,
                    });
                    return false;
                } else {
                    Toast.fire({
                        icon: "success",
                        title: res.data.message,
                    });
                    setGoaSubmitForm(false);
                    setImage({ preview: "", photoname: "", photo_type: "" });
                }
            })
            .catch((err) => {
                console.log(err);
                setGoaSubmitForm(true);
            });
    };
    return (
        <Fragment>
            {goaSubmitForm ? (
                <div className="container flex-fill">
                    <div className="mt-5">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <Link to="/Home">
                                    <img
                                        src={process.env.PUBLIC_URL + "images/Govt_of_Goa.png"}
                                        className="goa_logo"
                                        alt = "Govt of Goa"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className={classes.form_head}>
                                    Claim Application for prize of the goa state lotteries
                                </h3>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mt-5">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="col-lg-12 mt-5 ml-2">
                                        <p className={mainclasses.para1}>To,</p>
                                        <p className={mainclasses.para1}>The Director</p>
                                        <p className={mainclasses.para1}>
                                            Director Of The Small Saving & Lotteries.
                                        </p>
                                        <p className={mainclasses.para1}>Government Of Goa,</p>
                                        <p className={mainclasses.para1}>Serra Building Altinho,</p>
                                        <p className={mainclasses.para1}>Panji - Goa</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <div className="row">
                                        <div className={`col-lg-6 col-6 ${classes.uplimg_div}`}>
                                            <img
                                                id="photo"
                                                src={image.preview}
                                                alt={image.photoname}
                                                className={classes.uploadImgPreview}
                                            />
                                            <input
                                                id="photoname"
                                                accept=".jpef, .png, .jpg"
                                                type="file"
                                                defaultValue={image.photoname}
                                                className={classes.upl_inpt}
                                                onChange={handleChangeImage}
                                            />
                                            <label
                                                htmlFor="photoname"
                                                className={classes.uplimg_text}
                                            >
                                                Upload Photo <i className="fa fa-camera" />{" "}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-5">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <p className={mainclasses.para1}>
                                        Sub: Claim Application For Prize Of The Goa State Lotteries
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-5">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className={`${mainclasses.para1} pl-2`}>
                                        Claimed Details:
                                    </p>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Full Name Of The prize Winner (Beneficiary) *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="name"
                                        id="name"
                                        autoComplete="off"
                                        {...register("name", {
                                            required: true,
                                            pattern: /^[A-Za-z ]+$/i,
                                        })}
                                    />
                                    {errors?.name?.type === "required" && (
                                        <p className={classes.error_p}>
                                            Full Name of prize winner is required
                                        </p>
                                    )}
                                    {errors?.name?.type === "pattern" && (
                                        <p className={classes.error_p}>
                                            Name can only have characters
                                        </p>
                                    )}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Address 1 *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="address1"
                                        id="address1"
                                        autoComplete="off"
                                        {...register("address1", {
                                            required: true,
                                        })}
                                    />
                                    {errors.address1 && (
                                        <p className={classes.error_p}>Address1 is required</p>
                                    )}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Address 2 *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="address2"
                                        id="address2"
                                        {...register("address2", {
                                            required: true,
                                        })}
                                        autoComplete="off"
                                    />
                                    {errors.address2 && (
                                        <p className={classes.error_p}>Address2 is required</p>
                                    )}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Father's/Husband's Name *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="fname"
                                        id="fname"
                                        {...register("fname", {
                                            required: true,
                                            pattern: /^[A-Za-z ]+$/i,
                                        })}
                                        autoComplete="off"
                                    />
                                    {errors?.fname?.type === "required" && (
                                        <p className={classes.error_p}>
                                            Father's/Husband's name is required
                                        </p>
                                    )}
                                    {errors?.fname?.type === "pattern" && (
                                        <p className={classes.error_p}>
                                            Name can only have characters
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="My Contact No is  *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="mobile"
                                        id="mobile"
                                        maxLength="10"
                                        minLength="10"
                                        {...register("mobile", {
                                            required: true,
                                            minLength: "10",
                                            maxLength: "10",
                                            pattern: /^[0-9]+$/i,
                                        })}
                                        autoComplete="off"
                                    />
                                    {errors.mobile && (
                                        <p className={classes.error_p}>
                                            Please enter a valid Mobile Number
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 p-0">
                                            <Controller
                                                control={control}
                                                name="dob"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText="Date Of Birth *"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        dateFormat="dd/MM/yyyy"
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        adjustDateOnChange
                                                        minDate= {subYears(new Date(), 100)}
                                                        maxDate={subYears(new Date(), 18)}
                                                        className={`form-control ${classes.form_inp}  ${classes.form_datepicker}`}
                                                    />
                                                )}
                                            />
                                            <i
                                                className={`fa fa-calendar  ${classes.form_icon}`}
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                        <div className="col-lg-5 mt-4">
                                            <label className={`${classes.form_label} ml-2 mr-3`}>
                                                Profession
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Nature Of Bussiness *"
                                                className={`form-control  ${classes.form_inp_busin1}`}
                                                name="natureof_business"
                                                id="natureof_business"
                                                {...register("natureof_business", {
                                                    required: true,
                                                    pattern: /^[A-Za-z ]+$/i,
                                                })}
                                            />
                                            {errors.natureof_business && (
                                                <p className={classes.error_p}>
                                                    Please enter a valid Nature Of Bussiness
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Name Of The Organization *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="organisation_name"
                                        {...register("organisation_name", {
                                            required: true,
                                            pattern: /^[A-Za-z0-9. ]+$/i,
                                        })}
                                    />
                                    {errors.mobile && (
                                        <p className={classes.error_p}>
                                            Please enter a valid Organization Name
                                        </p>
                                    )}
                                </div>

                                <div className="col-lg-12 mt-4">
                                    <input
                                        type="text"
                                        placeholder="Pan If Any *"
                                        className={`form-control  ${classes.form_inp}`}
                                        name="panno"
                                        minLength="10"
                                        maxLength="10"
                                        id="panno"
                                        {...register("panno", {
                                            required: true,
                                            pattern: /^[A-Za-z0-9]+$/i,
                                            minLength: "10",
                                            maxLength: "10",
                                        })}
                                    />
                                    {errors.panno && (
                                        <p className={classes.error_p}>
                                            Please Enter a valid Pan Number
                                        </p>
                                    )}
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Bank A/c No. of Prize Winner (Beneficiary) *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="bank_account_no"
                                                {...register("bank_account_no", {
                                                    required: true,
                                                    pattern: /^[0-9]+$/i,
                                                })}
                                            />
                                            {errors.bank_account_no && (
                                                <p className={classes.error_p}>
                                                    Please Enter Valid Bank Account Number
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="IFSC Code *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="ifsc_code"
                                                {...register("ifsc_code", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9 ]+$/i,
                                                })}
                                            />
                                            {errors.ifsc_code && (
                                                <p className={classes.error_p}>
                                                    Please Enter valid IFSC Code
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Bank Name *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="bank_name"
                                                {...register("bank_name", {
                                                    required: true,
                                                    pattern: /^[A-Za-z ]+$/i,
                                                })}
                                            />
                                            {errors.bank_name && (
                                                <p className={classes.error_p}>
                                                    Please Enter valid Bank Name
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Branch *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="bank_branch"
                                                {...register("bank_branch", {
                                                    required: true,
                                                    pattern: /^[A-Za-z ]+$/i,
                                                })}
                                            />
                                            {errors.bank_branch && (
                                                <p className={classes.error_p}>
                                                    Please Enter valid Bank Branch Name
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 mt-5">
                                    <p className={`${mainclasses.para1} pl-2`}>
                                        Winning Ticket Details:
                                    </p>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Winning Ticket Transaction No *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="transno"
                                                {...register("transno", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9 ]+$/i,
                                                })}
                                            />
                                            {errors.transno && (
                                                <p className={classes.error_p}>
                                                    Please Enter valid Ticket Transaction No
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Draw No *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="drawno"
                                                {...register("drawno", {
                                                    required: true,
                                                    pattern: /^[0-9]+$/i,
                                                })}
                                            />
                                            {errors.drawno && (
                                                <p className={classes.error_p}>
                                                    Enter Valid Draw Number!
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <Controller
                                                control={control}
                                                name="drawdate"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText="Date Of Draw *"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        dateFormat="dd/MM/yyyy"
                                                        className={`form-control ${classes.form_inp}  ${classes.form_datepicker}`}
                                                    />
                                                )}
                                            />
                                            <i
                                                className={`fa fa-calendar  ${classes.form_icon}`}
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                        <Controller
                                                control={control}
                                                name="drawtime"
                                                render={({ field }) => (                     
                                                    <Datetime inputProps={{placeholder:"DrawTime *"}} dateFormat={false} timeFormat={"hh:mm A"} input={true} onChange={(time) => field.onChange(time)} className={`${classes.form_datetime} ${classes.form_inp}`} />
                                                    // <DatePicker
                                                    //     placeholderText="DrawTime *"
                                                    //     onChange={(date) => field.onChange(date)}
                                                    //     selected={field.value}
                                                    //     dateFormat="dd/MM/yyyy"
                                                    //     className={`form-control ${classes.form_inp}  ${classes.form_datepicker}`}
                                                    // />
                                                )}
                                            />
                                            <i
                                                className={`fa fa-clock-o  ${classes.form_icon}`}
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Winning Number *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="ticketno"
                                                {...register("ticketno", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9\- ]+$/i,
                                                })}
                                            />
                                            {errors.ticketno && (
                                                <p className={classes.error_p}>
                                                    Enter Valid Ticket Number!
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Name Of Lottery *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="lotteryname"
                                                {...register("lotteryname", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9\- ]+$/i,
                                                })}
                                            />
                                            {errors.lotteryname && (
                                                <p className={classes.error_p}>
                                                    Enter Valid Lottery Name!
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Rank Of Prize *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="prizerank"
                                                {...register("prizerank", {
                                                    required: true,
                                                    pattern: /^[0-9]+$/i,
                                                })}
                                            />
                                            {errors.prizerank && (
                                                <p className={classes.error_p}>
                                                    Prize Rank Should be Numeric
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Prize Amount *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="prizeamt"
                                                {...register("prizeamt", {
                                                    required: true,
                                                    pattern: /^[0-9]+$/i,
                                                })}
                                            />
                                            {errors.prizeamt && (
                                                <p className={classes.error_p}>
                                                    Prize Amount Should Be Numeric
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4 p-0">
                                            <input
                                                type="text"
                                                placeholder="Name Of Retailer (Point Of Sale) *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="retname"
                                                {...register("retname", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9 ]+$/i,
                                                })}
                                            />
                                            {errors.retname && (
                                                <p className={classes.error_p}>
                                                    Enter a valid Retailer Name
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <input
                                                type="text"
                                                placeholder="Retailer Code *"
                                                className={`form-control  ${classes.form_inp}`}
                                                name="retcode"
                                                id="retcode"
                                                {...register("retcode", {
                                                    required: true,
                                                    pattern: /^[A-Za-z0-9]+$/i,
                                                })}
                                            />
                                            {errors.retcode && (
                                                <p className={classes.error_p}>
                                                    Enter a valid Retailer Code
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-1">
                                    <p className="para1">Enclose: </p>
                                </div>
                                <div className="col-lg-10">
                                    <p className={mainclasses.para1}>
                                        1. Original Price - Winning Ticket
                                    </p>
                                    <p className={mainclasses.para1}>
                                        2. Proof Of Date Of Birth{" "}
                                    </p>
                                    <p className={mainclasses.para1}>3. Proof Of Address </p>
                                    <p className={mainclasses.para1}>4. Pan Card Photo Copy </p>
                                    <p className={mainclasses.para1}>
                                        5. 4 Passport Size Photography & Affidavit Duly Attested by
                                        Notary (On Rs. 20/- Stamp Paper)
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 ml-auto text-center mt-5">
                                    <p className={classes.sign_text}>Signature</p>
                                </div>
                            </div>{" "}
                            <div className="col-lg-12 text-center mt-5">
                                <button type="submit" className={`btn ${classes.btn_cont}`}>
                                    Submit
                                </button>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <span>
                                    Download Claim Form{" "}
                                    <a href="files/goa_Claim_form.pdf" download>
                                        Click
                                    </a>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <GoaFormSubmit goaFormData={formdataArr} />
            )}
        </Fragment>
    );
};

export default GoaForm;
