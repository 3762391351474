import { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import API from "../../Api";
import styles from '../../style.module.css';
import Card from "../UI/Card/Card";
import classes from "./Notification.module.css";

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const lastCardRef = useRef(null);

  const fetchNotificationList = useCallback(async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      const uri = `get-notifications/APP/${page}`;
      const res = await API.get(uri);
      if (res.data.data.length === 0) {
        setHasMore(false);
      } else {
        setNotificationData(prevData => [...prevData, ...res.data.data]);
        setPage(prevPage => prevPage + 1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [page, isLoading, hasMore]);

  // Initial load
  useEffect(() => {
    fetchNotificationList();
  }, []); // Empty dependency array ensures this runs only once on initial mount

  // Scroll-based loading
  useEffect(() => {
    if (!lastCardRef.current || !hasMore || isLoading) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchNotificationList();
      }
    }, {
      rootMargin: "0px 0px 100px 0px", // Adjust this value to load earlier or later
      threshold: 1.0
    });

    observer.observe(lastCardRef.current);

    return () => {
      if (lastCardRef.current) {
        observer.unobserve(lastCardRef.current);
      }
    };
  }, [hasMore, isLoading, lastCardRef.current]); // Adding lastCardRef.current to the dependency array to ensure it updates

  const arrayBufferToBase64 = (buffer) => {
    if (!buffer || buffer.byteLength === 0) {
        console.error('Buffer is empty or invalid');
        return '';
    }
    
    const bytes = new Uint8Array(buffer);
    let binary = '';
    const chunkSize = 8192; // Process in 8KB chunks

    for (let i = 0; i < bytes.length; i += chunkSize) {
        binary += String.fromCharCode.apply(null, bytes.subarray(i, i + chunkSize));
    }

    try {
        return binary;
    } catch (error) {
        console.error('Failed to convert buffer to base64:', error);
        return '';
    }
  };

  const removeHTMLTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  return (
    <>
      <Helmet>
        <title>Notification | Rajshree Lottery</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Here you can checkout latest updates of rajshree lottery. Get latest rajshree lottery notifications." />
      </Helmet>
      <div className={`flex-fill ${styles.containerFluid}`}>
        <div className={`container-fluid mt-5 ${styles.row}`}>
          <div className="col-lg-8 mx-auto">
            <h2 className={styles.head}>Notifications</h2>
          </div>
        </div>
        <div className={`container-fluid mt-2 ${styles.row}`}>
          {notificationData.length > 0 ? (
            notificationData.map((item, index) => (
              <a 
                key={`card-${index}`} 
                href={item.deeplink ? item.deeplink : undefined} 
                target="_blank" 
                rel="noopener noreferrer"
                ref={index === notificationData.length - 1 ? lastCardRef : null}
              >
                <Card className={`${classes.notificationCard}`}>
                  <div className={`row ${classes.notificationCard__header}`}>
                    {item.image_data && item.image_data.data ? (
                      <img
                        className="card-img-top"
                        src={`data:image/png;base64,${arrayBufferToBase64(item.image_data.data)}`}
                        alt="Card image cap"
                        onError={(e) => { 
                            console.error('Image failed to load', e);
                            e.target.style.display = 'none'; 
                        }}
                      />
                    ) : null}
                    <div className="card-body">
                      <h5 className="card-title">{removeHTMLTags(String.fromCharCode(...new Uint8Array(item.msgtitle.data)))}</h5>
                      <p className="card-text">{removeHTMLTags(String.fromCharCode(...new Uint8Array(item.msgcontent.data)))}</p>
                    </div>
                  </div>
                </Card>
              </a>
            ))
          ) : (
            <div className="row">
              <div className="col-md-12 text-center p-0"> 
                <h5>No notifications available.</h5>
              </div>
            </div>
          )}
        </div>
          {isLoading && 
          <div className="row">
            <div className="col-md-12 text-center p-0"> 
              <h5>Loading more notifications...</h5>
            </div>
        </div>}
      </div>
    </>
  );
};

export default Notification;
