import classes from "./Loader.module.css";
import ReactDOM from "react-dom";

const Loader = (props) => {
  return (
    <>
      {
        ReactDOM.createPortal(
          <div className={classes.backdrop}>
            <center>
              <img className={classes.loadimg} src={process.env.PUBLIC_URL + "/images/loop.gif"} alt="" />
            </center>
          </div>,
          document.getElementById('loader-root'))
      }
    </>
  );
};

export default Loader;
