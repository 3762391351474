import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import mainclasses from "../../style.module.css";
import API from "../../Api";
import { $ } from "react-jquery-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  loop: true,
  margin: 10,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
      loop: true,
      autoplay: true,
    },
    600: {
      items: 1,
      loop: true,
      autoplay: true,
    },
    1000: {
      items: 3,
      loop: true,
      margin: 0,
      autoplay: true,
    },
  },
};
const Home = (props) => {
  const [bannerData, setBannerData] = useState([]);
  const [isLoaded, setLoad] = useState(false);
  useEffect(() => {
    let data = {};
    API.post(`promobanners`, data)
      .then((res) => {
        if (res.data.status === "error") {
          setBannerData([]);
        } else {
          setBannerData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (bannerData) {
      setLoad(true);
    }
  }, [bannerData]);

  if (!isLoaded) {
    return null;
  }

  // Function to handle Craousal Click event
  const handleCarouselClick = (carouselData) => {
    if (
      carouselData &&
      carouselData.linked_url &&
      carouselData.linked_url !== ""
    )
      window.open(carouselData.linked_url, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>Rajshree Lottery Results & Updates | Rajshree Lottery</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Here you can check rajshree lottery results and updates. You can watch rajshree lottery live result also check old rajshree lottery results."
        />
      </Helmet>
      <div>
        {bannerData && bannerData.length > 0 && (
          <OwlCarousel
            className="slider-items owl-carousel owl-theme p-0"
            {...options}
          >
            {bannerData.map((index) => (
              <div key={"divimage-" + index.image_id} className="item">
                <img
                  key={"image-" + index.image_id}
                  src={process.env.PUBLIC_URL + "/images/" + index.image_name}
                  alt={index.image_description}
                  onClick={() => handleCarouselClick(index)}
                />
              </div>
            ))}
          </OwlCarousel>
        )}
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-5 mx-auto text-center">
            <h3 className={mainclasses.head1}>
              Rajshree Lottery Result | Check Rajshree Lottery Result
            </h3>
            <p className={mainclasses.para}>
              Here you can check rajshree lottery results and updates. You can
              watch rajshree lottery live result also check old rajshree lottery
              results.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
