import { useState, useRef } from "react";
import classes from "./TicketQry.module.css";
import Input from "../UI/Input/Input";
import { validateInput } from "../UI/Input/ValidateInput";
import API from "../../Api";
import Swal from "sweetalert2";

const TicketQry = (props) => {
  const [showResult, setResult] = useState(false);
  const [ticketNo, setTicketNo] = useState("");
  const seriesInputRef = useRef(null);
  const ticketInputRef = useRef(null);
  const Toast = Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
  });

  const handleSeriesInputChange = () => {
    const series = seriesInputRef.current.value;
    const maxSeriesLength = props.cardDetails.series;

    if (series.length === maxSeriesLength) {
      // Move focus to ticket input when max length is reached
      ticketInputRef.current.focus();
    }
  };

  const showTicketWinner = (numbersize, lotcode, drawdate) => {
    const series = seriesInputRef.current.value;
    const number = ticketInputRef.current.value;

    if (series === "") {
      Toast.fire({
        icon: "error",
        title: "Series Should not be Blank!",
      });
      seriesInputRef.current.focus();
      setResult(false);
      return false;
    }

    if (number.length < numbersize) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Valid Ticket Number!",
      });
      ticketInputRef.current.focus();
      setResult(false);
      return false;
    }
    const data = {
      drawdate,
      lotcode,
      series,
      number,
    };
    API.post("get-ticket-status", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "error") {
          Toast.fire({
            icon: "success",
            title: res.data.message,
          });
          setResult(false);
          return false;
        } else {
          setTicketNo(res.data.data);
          setResult(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`row mt-1`}>
      <div className={`col-lg-12 col-12 ${classes.ticketQryHead}`}>
        <span className={`${classes.ticketQryHead_text}`}>
          Type In Your Ticket Numbers Below
        </span>
        <i
          className={`fa fa-close ${classes.ticketQryNum_icon}`}
          onClick={props.HideDiv}
        />
      </div>
      <div className={`col-lg-12 col-12 ${classes.ticketQryNum}`}>
        <div className="row">
          <div className="col-lg-8 textCenter">
            <Input
              input={{
                placeholder: "Series",
                type: "text",
                className: `form-control ${classes.ticketQryNum_input}`,
                id: "series",
                name: "number-0",
                autoComplete: "off",
                maxLength: props.cardDetails.series,
              }}
              onKeyDown={validateInput}
              onChange={handleSeriesInputChange}
              ref={seriesInputRef}
            />
            <Input
              input={{
                placeholder: "Ticket Number",
                type: "text",
                name: "number",
                className: `form-control ${classes.ticketQryNum_input_number}`,
                autoComplete: "off",
                maxLength: props.cardDetails.number,
                id: `ticket_${props.cardDetails.lotcode}`,
              }}
              onKeyDown={validateInput}
              ref={ticketInputRef}
            />
          </div>
          <div className="col-lg-4">
            <input
              type="submit"
              className={`btn ${classes.ticketQry_bttn_verify}`}
              name="Verify"
              value="Verify"
              id={`verify_${props.cardDetails.lotcode}`}
              onClick={() =>
                showTicketWinner(
                  props.cardDetails.number,
                  props.cardDetails.lotcode,
                  props.cardDetails.drawdate
                )
              }
            />
          </div>
        </div>
      </div>
      {showResult && (
        <div
          className={`col-lg-12 col-12 ${classes.ticketQryNum_result_winner} ${
            ticketNo.prizeno === "" &&
            ticketNo.prizeamt === 0 &&
            classes.ticketQryNum_error_result_winner
          }`}
        >
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-8">
              <h4
                className={`${classes.ticketQryNum_result_text_winner} ${
                  ticketNo.prizeno === "" &&
                  ticketNo.prizeamt === 0 &&
                  classes.ticketQryNum_result_error_text_winner
                }`}
              >
                {ticketNo.lotname}
              </h4>
            </div>
            <div className="col-lg-2 col-sm-6 col-4">
              <h4
                className={`${classes.ticketQryNum_result_text_winner} ${
                  ticketNo.prizeno === "" &&
                  ticketNo.prizeamt === 0 &&
                  classes.ticketQryNum_result_error_text_winner
                }`}
              >
                {ticketNo.ticketno}
              </h4>
            </div>
            <div className="col-lg-2 col-sm-6 col-6">
              <h4
                className={`${classes.ticketQryNum_result_text_winner} ${
                  ticketNo.prizeno === "" &&
                  ticketNo.prizeamt === 0 &&
                  classes.ticketQryNum_result_error_text_winner
                }`}
              >
                {ticketNo.ticketstatus === "" ||
                ticketNo.ticketstatus !== "SOLD"
                  ? "Not Sold"
                  : ticketNo.ticketstatus}
              </h4>
            </div>
            <div className="col-lg-2 col-sm-6 col-6">
              <h4
                className={`${classes.ticketQryNum_result_text_winner} ${
                  ticketNo.prizeno === "" &&
                  ticketNo.prizeamt === 0 &&
                  classes.ticketQryNum_result_error_text_winner
                }`}
              >
                {ticketNo.prizeno === "" ? "No Winning" : ticketNo.prizeno}
              </h4>
            </div>
            <div className="col-lg-2 col-sm-6 col-12">
              <h4
                className={`${classes.ticketQryNum_result_text_winner} ${
                  ticketNo.prizeno === "" &&
                  ticketNo.prizeamt === 0 &&
                  classes.ticketQryNum_result_error_text_winner
                }`}
              >
                {ticketNo.prizeamt === 0 ? (
                  "Better Luck Next Time"
                ) : (
                  <span>&#8377; {ticketNo.prizeamt} </span>
                )}
              </h4>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketQry;
